@import url(https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,400;1,200;1,400&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-family: 'Exo', sans-serif;
  font-weight:800;
}

html {
  text-align: center;
  background-color: rgb(209, 94, 0);
  background-repeat: no-repeat;
}

.Header{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 150px;
}
.subHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

}

iframe {
  margin-bottom: 50px;
}

h1{
  font-size: 6rem;
  color: rgb(247, 246, 245);

}

h2{
  text-align: left;
  padding-left: 10%;
}

a{
  font-size: 2em;
  color: rgb(0, 0, 0);
}

.logo{
  width: 130px;
  padding-right: 50px;
}

.youtubelogo{
  width: 100px;
}

@media all and (max-width:630px) {

  .Header{
    padding: 1px;
  }

  h1{
    font-size: 2rem;
  }

  .logo{
    width: 110px;
    padding-right: 10px;
  }

  .youtubelogo{
    width: 70px;
  }

}
